
















































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Global, Props, User, Reports } from '@/store';
import { Prop, Watch } from 'vue-property-decorator';
import PropUtils from '@/modules/PropUtils';
import MetaDeviceFilter from '@/pages/reports/components/MetaDeviceFilter.vue';
import InventoryTable from '@/pages/reports/components/InventoryTable.vue';
import ConfirmDialog from '@/components/dialogs/ConfirmDialog.vue';
import { Types } from '@/modules/API';
import cloneDeep from 'lodash/cloneDeep';
import PropEditor from '@/components/PropEditor.vue';

@Component({
    components: {
        MetaDeviceFilter,
        InventoryTable,
        ConfirmDialog,
        PropEditor
    }
})
export default class InventoryReport extends Vue {
    @Props.State('list') projectProperties;
    @Props.Action('load') loadProjectProperties;
    @Props.State('fixture_meta_device_options') fixture_meta_device_options;
    @Props.State('cabinet_meta_device_options') cabinet_meta_device_options;
    @Global.State('readonly_user') readonly_user;
    @Global.State('lang') lang;
    @Global.Mutation('setPageTitle') setPageTitle;
    @Reports.Getter('commissionedCabinets') commissionedCabinets;
    @Reports.Getter('commissionedFixtures') commissionedFixtures;
    @Reports.State('reportsList') devices;
    @Reports.State('devicesCount') devicesCount;
    @User.State('project') project;
    @User.State('username') username;

    loading = true;
    message = 'No Data Available';
    noData = false;
    selectedDeviceType = {text: '', value: ''};
    deviceTypes = [];
    commissioned_tondos = [];
    commissioned_cabinets = [];
    commissioned_devices = [];
    search_fields = [];
    filter_fields = {};
    selectedTab = 'filter';
    searchClicked = false;
    deleteDialog = false;
    templates_prop_name = 'dashboard.report_templates';
    report_templates = [];
    report_templates_property = {};
    selectedReport = {name: '', type: '', values: {}, fields: ''};

    mounted(){
        this.setPageTitle('Inventory Management Report');
        this.generatePageData();
    }

    @Watch('devices')
    @Watch('devicesCount')
    generatePageData(){
        if (this.devices.length !== this.devicesCount) return;
        this.loading = true;
        this.search_fields = [];
        this.setMetadataFieldsLists();
        this.updateCommissionedDevices();
        this.setReportTemplatesProperty();
        this.loading = false;
    }

    @Watch('projectProperties')
    setReportTemplatesProperty(){
        const templates_property = PropUtils.getProperty(this.projectProperties, this.templates_prop_name);
        this.report_templates_property = templates_property && templates_property.value || {};

        if (!this.report_templates_property[this.username] 
            || (this.report_templates_property[this.username] && !this.report_templates_property[this.username].inventory)){
            this.report_templates_property[this.username] = {inventory: []};
        }

        this.setTemplates();
    }

    @Watch('selectedTab')
    showTemplates(){
        if (this.selectedTab === 'template'){
            this.selectedReport = this.getDefaultTemplate();
            this.setReportTemplatesProperty();
        }else {
            this.searchClicked = false;
            this.selectedDeviceType = this.deviceTypes[0];
        }
        this.clearTable();
    }

    @Watch('lang')
    setTemplates(){
        if (this.selectedTab === 'template'){
            this.report_templates = this.report_templates_property[this.username].inventory.map((report) => {
                return {
                    name: report.name,
                    type: report.type,
                    values: report.values,
                    fields_string: Object.entries(report.values).reduce((acc, [field, values]) => {
                        const values_string = values['map']((value) => this.$t(value)).join(',');
                        return acc 
                            ? `${acc}; ${this.$t(field)}: ${values_string}`
                            : `${this.$t(field)}: ${values_string}`;
                    }, '')
                };
            });
            if (this.selectedReport && this.selectedReport.name){
                this.selectedReport = this.report_templates.find((report) => report.name === this.selectedReport.name);
            }
        }
    }

    @Watch('selectedReport')
    updateTableData(){
        this.searchClicked = false;
        this.selectedDeviceType = this.deviceTypes.find((type) => type.value === this.selectedReport.type);
        this.search_fields = Object.entries(this.selectedReport.values).map(([field, value]) => {
            return {
                name: field,
                selected: value
            };
        });
        this.searchClicked = true;
    }

    removeTemplate(){
        const property_details = {
            name: this.templates_prop_name,
            objectID: this.project.id,
            objectType: Types.PROJECTS
        };
        
        this.report_templates_property[this.username].inventory = this.report_templates
            .filter((report) => report.name !== this.selectedReport.name)
            .map((report) => ({name: report.name, type: report.type, values: report.values}));

        (this.$refs.propEditor as PropEditor).save(
            this.report_templates_property,
            property_details,
            () => this.loadProjectProperties({ projectId: this.project.id }),
            'The report was successfully deleted'
        );
        this.selectedReport = this.getDefaultTemplate();
        this.deleteDialog = false;
    }

    getDefaultTemplate(){
        return {name: '', type: '', values: {}, fields: ''};
    }

    getType(type){
        return type === 'tondo'
            ? 'Tondo Device'
            : 'Cabinet Monitor';
    }

    setMetadataFieldsLists(){
        if (this.commissionedFixtures.length && Object.keys(this.fixture_meta_device_options).length) {
            this.deviceTypes.push({ text: 'Tondo Device', value: 'tondo'});
        }
        if (this.commissionedCabinets.length && Object.keys(this.cabinet_meta_device_options).length) {
            this.deviceTypes.push({text: 'Cabinet Monitor', value: 'cabinet'});
        }
        this.noData = this.deviceTypes.length ? false : true;
        if (!this.noData) this.selectedDeviceType = this.deviceTypes[0];
    }

    getMetadataProperty(property_name){
        const form_meta_device = PropUtils.getProperty(this.projectProperties, property_name);
        if (!form_meta_device) console.log(`There is no ${property_name} project property`);
        return form_meta_device && form_meta_device['value']['fields'] ? form_meta_device['value']['fields'] : [];
    }

    updateSearchFields(fields){
        this.search_fields = cloneDeep(fields);
        this.searchClicked = true;
    }

    updateSearchClicked(value){
        this.searchClicked = value;
    }

    updateSelectedType(type){
        this.selectedDeviceType = type;
    }

    @Watch('selectedDeviceType')
    updateCommissionedDevices(){
        this.commissioned_devices = this.selectedDeviceType.value === 'tondo'
            ? this.commissionedFixtures
            : this.commissionedCabinets;
        
        if (this.selectedTab === 'filter') this.clearTable();
    }

    clearTable(){
        this.search_fields = [];
    }
}

